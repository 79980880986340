import { getSpiEcomConfig, getMaintenanceStatus } from 'apis/keycloak';
import { ecomConfig, isSimpleLogin } from 'config';
import { EcomConfig, SpiEcomConfig } from 'types/config';

const updateEcomConfigBySpiPage = (data: SpiEcomConfig) => {
  if (!data) return;

  const { captcha_host, shopping_site_host, strapi_url, captcha_switch, ...restData } = data;
  Object.assign(ecomConfig, {
    ...restData,
    captcha_url: `https://${captcha_host}`,
    disable_captcha: captcha_switch === false,
  } as Partial<EcomConfig>);
};

const includes = (names: string[], key: string) => {
  return !!names.find((name) => name.includes(key));
};
const updateEcomConfigByHost = () => {
  const toUpdate = {
    running_env: 'production',
    ecommerce_web_url: 'https://www.igus.cn',
    strapi_api_url: 'https://www.igus.cn/content',
  };
  const hostNames = window.location.host.split('.');
  if (includes(hostNames, 'dev') || includes(hostNames, 'localhost')) {
    toUpdate.running_env = 'development';
    toUpdate.ecommerce_web_url = 'https://www.dev.igus.cn';
    toUpdate.strapi_api_url = 'https://www.dev.igus.cn/content';
  } else if (includes(hostNames, 'int')) {
    toUpdate.running_env = 'integration';
    toUpdate.ecommerce_web_url = 'https://www.int.igus.cn';
    toUpdate.strapi_api_url = 'https://www.int.igus.cn/content';
  } else if (includes(hostNames, 'uat')) {
    toUpdate.running_env = 'staging';
    toUpdate.ecommerce_web_url = 'https://www.uat.igus.cn';
    toUpdate.strapi_api_url = 'https://www.uat.igus.cn/content';
  }
  Object.assign(ecomConfig, toUpdate);
};

export const setupConfig = async () => {
  updateEcomConfigByHost();
  if (!isSimpleLogin) {
    const [maintenanceRes, spiEcomConfigRes] = await Promise.all([
      getMaintenanceStatus(),
      getSpiEcomConfig(),
    ]);
    updateEcomConfigBySpiPage(spiEcomConfigRes?.data);
    ecomConfig.is_maintenance = maintenanceRes?.data === 'ON';
  }
};
