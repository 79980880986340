import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import mocks from '../mock';
import { kcContext as context } from 'kcApp/KcLoginApp/kcContext';

const getBaseUrl = () => {
  const kcContext = context || (window as any).kcContext;
  if (!kcContext?.realm) return '';

  const realmPath = `/realms/${kcContext!.realm?.name}`;
  const splitUrls = kcContext!.url?.loginAction?.split(realmPath);

  if (splitUrls?.length > 1) {
    return `${splitUrls[0]}${realmPath}`;
  } else {
    return realmPath;
  }
};

const request = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

const get = async <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  config?: AxiosRequestConfig<D>
): Promise<R> => {
  const mockData = mocks[url];
  if (mockData) {
    console.log('mock get request =>', url, config?.params);
    if (mockData.data.error) {
      return Promise.reject({ response: mockData });
    } else {
      return Promise.resolve(mockData);
    }
  } else {
    return request.get(url, config);
  }
};

const post = async <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>
): Promise<R> => {
  const mockData = mocks[url];
  if (mockData) {
    console.log('mock post request =>', url, data);
    if (mockData.data.error) {
      return Promise.reject({
        response: mockData,
      });
    } else {
      return Promise.resolve(mockData);
    }
  } else {
    return request.post(url, data, config);
  }
};

export const triggerSendVerificationCode = (
  value: string,
  captcha_token: string = '',
  type: 'LOGIN' | 'REGISTER' | 'BIND_ACCOUNT' | 'RESET_PASSWORD',
  locale: string,
  account_field_name: string
) => {
  return post('/smsemail/pin-code', {
    value,
    type,
    captcha_token,
    locale,
    account_field_name,
  });
};

export const getSpiEcomConfig = () => {
  return get('/ecom-configurations');
};

export const getMaintenanceStatus = (): Promise<{ data: 'ON' | 'OFF' }> => {
  return get('/maintenance-mode');
};
