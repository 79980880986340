import keycloakApis from './keycloak-apis';

let mock = {};

if (process.env.NODE_ENV === 'development') {
  mock = {
    ...keycloakApis,
  };
}

export default mock as any;
