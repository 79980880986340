// import { getKcContext } from 'keycloakify/lib/getKcContext';
// import { getKcContext } from 'keycloakify/login';
import { ExtendPageInfo } from 'types/kcContext.extension';
import mockSetting from '../../mock/setting';
import { getKcContext } from './KcContext/getKcContext';

export const { kcContext } = getKcContext<ExtendPageInfo>({
  mockPageId: mockSetting.pageId,
  mockData: [
    {
      pageId: 'index.ftl',
    },
  ],
});

export type KcContext = NonNullable<typeof kcContext>;
