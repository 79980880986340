// import type { KcContext, Attribute } from "./KcContext";
// import { kcContextMocks, kcContextCommonMock } from "./kcContextMocks";
import type { DeepPartial } from 'keycloakify/tools/DeepPartial';
import { deepAssign } from 'keycloakify/tools/deepAssign';
import { symToStr } from 'tsafe/symToStr';
import { ExtendKcContext, getKcContextFromWindow } from './getKcContextFromWindow';
import { KcContext, kcWelcomeContextMock } from './KcContext';

export function createGetKcContext<KcContextExtension extends { pageId: string } = never>(params?: {
  mockData?: readonly DeepPartial<ExtendKcContext<KcContextExtension>>[];
}) {
  const { mockData } = params ?? {};

  function getKcContext<
    PageId extends ExtendKcContext<KcContextExtension>['pageId'] | undefined = undefined
  >(params?: {
    mockPageId?: PageId;
    storyPartialKcContext?: DeepPartial<
      Extract<ExtendKcContext<KcContextExtension>, { pageId: PageId }>
    >;
  }): {
    kcContext: PageId extends undefined
      ? ExtendKcContext<KcContextExtension> | undefined
      : Extract<ExtendKcContext<KcContextExtension>, { pageId: PageId }>;
  } {
    const { mockPageId, storyPartialKcContext } = params ?? {};

    const realKcContext = getKcContextFromWindow<KcContextExtension>();

    if (mockPageId !== undefined && realKcContext === undefined) {
      //TODO maybe trow if no mock fo custom page

      console.log(
        `%cKeycloakify: ${symToStr({ mockPageId })} set to ${mockPageId}.`,
        'background: red; color: yellow; font-size: medium'
      );

      const kcContextDefaultMock = kcWelcomeContextMock;

      const partialKcContextCustomMock = (() => {
        const out: DeepPartial<ExtendKcContext<KcContextExtension>> = {};

        const mockDataPick = mockData?.find(({ pageId }) => pageId === mockPageId);

        if (mockDataPick !== undefined) {
          deepAssign({
            target: out,
            source: mockDataPick,
          });
        }

        if (storyPartialKcContext !== undefined) {
          deepAssign({
            target: out,
            source: storyPartialKcContext,
          });
        }

        return Object.keys(out).length === 0 ? undefined : out;
      })();

      if (kcContextDefaultMock === undefined && partialKcContextCustomMock === undefined) {
        console.warn(
          [
            `WARNING: You declared the non build in page ${mockPageId} but you didn't `,
            `provide mock data needed to debug the page outside of Keycloak as you are trying to do now.`,
            `Please check the documentation of the getKcContext function`,
          ].join('\n')
        );
      }

      const kcContext: any = {};

      deepAssign({
        target: kcContext,
        source:
          kcContextDefaultMock !== undefined
            ? kcContextDefaultMock
            : { ...kcWelcomeContextMock, pageId: mockPageId },
      });

      return { kcContext };
    }

    if (realKcContext === undefined) {
      return { kcContext: undefined as any };
    }

    // resourcesPath has different path in Welcome page
    if (!realKcContext?.url?.resourcesPath) {
      (realKcContext as KcContext).url = {
        resourcesPath: realKcContext.resourcesPath,
      };
    }

    if (!realKcContext.locale) {
      (realKcContext as KcContext).locale = {
        supported: [
          {
            url: '?kc_locale=zh-CN',
            label: '中文',
            languageTag: 'zh-CN',
          },
          {
            url: '?kc_locale=en',
            label: 'en',
            languageTag: 'en',
          },
        ],
        currentLanguageTag: getLocaleFromLocation() || 'zh-CN',
      };
    }

    return { kcContext: realKcContext as any };
  }

  return { getKcContext };
}

function getLocaleFromLocation() {
  return new URLSearchParams(window.location.search.split('/')[0]).get('kc_locale');
}
