import { resourcesDirPathRelativeToPublicDir } from 'keycloakify/bin/mockTestingResourcesPath';
import { pathJoin } from 'keycloakify/bin/tools/pathJoin';
const PUBLIC_URL = process.env['PUBLIC_URL'] ?? '/';

export type KcContext = {
  pageId: 'index.ftl';
  keycloakifyVersion: string;
  themeType: 'welcome';
  resourcesPath: string;
  url?: {
    resourcesPath: string;
  };
  locale?: {
    supported: {
      url: string;
      label: string;
      languageTag: string;
    }[];
    currentLanguageTag: string;
  };
  messagesPerField: {
    printIfExists: <T>(fieldName: string, x: T) => T | undefined;
    existsError: (fieldName: string) => boolean;
    get: (fieldName: string) => string;
    exists: (fieldName: string) => boolean;
  };
};

export const kcWelcomeContextMock: KcContext = {
  pageId: 'index.ftl',
  keycloakifyVersion: '7.0.mock',
  themeType: 'welcome',
  resourcesPath: pathJoin(PUBLIC_URL, resourcesDirPathRelativeToPublicDir),
  locale: {
    supported: [
      {
        url: '',
        label: '中文',
        languageTag: 'zh-CN',
      },
      {
        url: '',
        label: 'EN',
        languageTag: 'EN',
      },
    ],
    currentLanguageTag: 'zh-CN',
  },
  messagesPerField: {
    printIfExists: function <T>(fieldName: string, x: T): T | undefined {
      throw new Error('Function not implemented.');
    },
    existsError: function (fieldName: string): boolean {
      throw new Error('Function not implemented.');
    },
    get: function (fieldName: string): string {
      throw new Error('Function not implemented.');
    },
    exists: function (fieldName: string): boolean {
      throw new Error('Function not implemented.');
    },
  },
};
