import { createRoot } from 'react-dom/client';
import { StrictMode, lazy, Suspense } from 'react';
import { debounce } from 'lodash';
import { kcContext as kcLoginThemeContext } from './kcApp/KcLoginApp/kcContext';
import { kcContext as kcWelcomeThemeContext } from './kcApp/KcWelcomeApp/kcContext';
import { setupConfig } from './utils/config';
import './styles/global.scss';

const KcLoginApp = lazy(() => import('./kcApp/KcLoginApp'));
const KcWelcomeApp = lazy(() => import('./kcApp/KcWelcomeApp'));

(window as any).kcContext = kcLoginThemeContext ? kcLoginThemeContext : kcWelcomeThemeContext;

const setHtmlRootFontsize = () => {
  // 1195px min desktop screen width
  // 1920px+ oversize screen width
  const screenWidth = document.body.clientWidth;
  const isOversizeView = screenWidth >= 1920;
  const isDesktopView = screenWidth >= 1195;
  const isPadRotateView = screenWidth >= 961;
  const isPadView = screenWidth >= 561;
  let rootSize = 14;
  if (isOversizeView) {
    rootSize = 16;
  } else if (isDesktopView) {
    // design desktop standard width: 1920
    rootSize = (screenWidth / 1920) * 16;
  } else if (isPadRotateView) {
    // design ipad rotate standard width: 1194
    rootSize = (screenWidth / 1194) * 14;
  } else if (isPadView) {
    // design ipad standard width: 834
    rootSize = (screenWidth / 834) * 14;
  }
  document.getElementsByTagName('html')[0].style.fontSize = rootSize + 'px';
};

window.addEventListener('resize', debounce(setHtmlRootFontsize, 300));

document.addEventListener('DOMContentLoaded', () => {
  setHtmlRootFontsize();
});

setupConfig().then(() => {
  createRoot(document.getElementById('root')!).render(
    <StrictMode>
      <Suspense>
        {(() => {
          if (kcLoginThemeContext !== undefined) {
            return <KcLoginApp kcContext={kcLoginThemeContext} />;
          }

          if (kcWelcomeThemeContext !== undefined) {
            return <KcWelcomeApp kcContext={kcWelcomeThemeContext} />;
          }

          return <KcWelcomeApp kcContext={kcWelcomeThemeContext!} />;
        })()}
      </Suspense>
    </StrictMode>
  );
});
