import { getKcContext } from 'keycloakify/login';
import { ExtendPageInfo } from 'types/kcContext.extension';
import mockSetting from '../../mock/setting';

export const { kcContext } =
  mockSetting.pageId === 'index.ftl'
    ? { kcContext: undefined }
    : getKcContext<ExtendPageInfo>({
        mockPageId: mockSetting.pageId,
        mockData: [
          {
            pageId: 'register.ftl',
            authorizedMailDomains: [
              'example.com',
              'another-example.com',
              '*.yet-another-example.com',
              '*.example.com',
              'hello-world.com',
            ],
            ...mockSetting.data,
          },
          {
            pageId: 'login.ftl',
            ...mockSetting.data,
          },
          {
            pageId: 'my-extra-page-2.ftl',
            someCustomValue: 'foo bar baz',
            ...mockSetting.data,
          },
          {
            //NOTE: You will either use register.ftl (legacy) or register-user-profile.ftl, not both
            pageId: 'register-user-profile.ftl',
            profile: {
              attributes: [
                {
                  validators: {
                    pattern: {
                      pattern: '^[a-zA-Z0-9]+$',
                      'ignore.empty.value': true,
                      // eslint-disable-next-line no-template-curly-in-string
                      'error-message': '${alphanumericalCharsOnly}',
                    },
                  },
                  //NOTE: To override the default mock value
                  value: undefined,
                  name: 'username',
                },
                {
                  validators: {
                    options: {
                      options: [
                        'male',
                        'female',
                        'non-binary',
                        'transgender',
                        'intersex',
                        'non_communicated',
                      ],
                    },
                  },
                  // eslint-disable-next-line no-template-curly-in-string
                  displayName: '${gender}',
                  annotations: {},
                  required: true,
                  groupAnnotations: {},
                  readOnly: false,
                  name: 'gender',
                },
              ],
            },
            ...mockSetting.data,
          },
          {
            pageId: 'login-reset-password.ftl',
            ...mockSetting.data,
          },
          {
            pageId: 'login-update-password.ftl',
            ...mockSetting.data,
          },
          {
            pageId: 'ecom-update-phone.ftl',
            ...mockSetting.data,
          },
          {
            pageId: 'ecom-update-email.ftl',
            ...mockSetting.data,
          },
        ],
      });

export type KcContext = NonNullable<typeof kcContext>;
